<template>
  <div class="GrilleTarif">
    <table>
      <thead>
        <tr>
          <th colspan="1">Nombre de calendriers</th>
          <th colspan="1">Port</th>
          <th colspan="1">Prix unitaire HT <br> (port inclus)</th>
        </tr>
      </thead>
      <tbody>
        
        <tr v-for="carton in cartons" :key="carton">
          <td>{{ (carton.unit>1 || carton.prixUnit>0)?`${carton.unit} calendriers`:`${carton.unit} calendrier à tester gratuitement` }}</td>
          <td>{{ (carton.port == 0) ? 'Franco' : carton.port + ' €' }}</td>
          <td>{{ prixunitHT(carton) }}</td>
        </tr>
        <tr>
          <td colspan="2">
            Vous n'avez pas de place, pas de structure ou peu d'anniversaires ?
            <br/>On s'occupe de tout ! <a class="link" href="/question-reponse">voir les FAQ</a>
          </td>
          <td>10.50 €</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'etapesTarifs',
  data() {
    return {
      cartons: [],
    }
  },
  methods: {
    prixunitHT(carton) {
      let prixUnitaire = (((carton.prixUnit * carton.unit) + carton.port) / carton.unit).toPrecision(3)
      return  (prixUnitaire==5 && carton.unit>=88)? prixUnitaire+"€ + réduction":prixUnitaire+"€"
    }
  },
  computed:{

  },
  created() {
    const api = require('../../api')

    let auth = api.getCookie('authentification')

  
    var requestOptions = {
      method: 'GET',
      headers: {
        "Authorization": "Bearer "+auth
      },
      redirect: 'follow'
    };

    fetch("https://www.aventbirthday-pro.fr/api/cartons", requestOptions)
      .then(response => response.json())
      .then(result => this.cartons = result)
      .catch(error => console.log('error', error));
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "./scss/_variables.scss";

.GrilleTarif {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
table {
  transition: all 1s ease;
  width: 50%;
  min-height: 50vh;
  background-color: $fancy;
  border-radius: 10px;
  padding: 1px;
  font-size: $fontsize;
  thead {
    background-color: $grey;

    th {
      border-radius: 5px;
      padding: 5px 4px;
    }
  }
  tbody {
    font-size: $fontsize;
    td {
      padding: 5px 4px;
      text-align: center;
      background-color: $blanchatre;
      border-radius: 3px;
    }
    .last-row {
      div {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
      }
      ul {
        display: flex;
        flex-direction: column;
        text-align: left;
        list-style: none;
        font-size: $fontsize;
        li {
          padding-left: 30px;
          &::before {
            content: "- ";
          }
        }
      }
    }
    img {
      height: 200px;
    }
  }
}

@media (max-width: 1000px) {
  table {
    width: 80%;
    transition: all 1s ease;
  }
}
@media (max-width: 600px) {
  table {
    width: 100%;
    transition: all 1s ease;
    tbody {
      .last-row {
        div {
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          align-items: center;
          ul {
            margin: 5px;
          }
        }
      }
    }
  }
}
</style>
