import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VueGtag from "vue-gtag";

const app = createApp(App);

app.use(router);

app.use(VueGtag, {
  config: { 
    id: "G-NZS64RLHQV",
  },
}, router); // <----- add your router here

app.mount("#app");

// createApp(App).use(router).mount('#app')
