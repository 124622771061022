<template>
  <div class="etapesTarifs">
    <div class="num">{{num}}</div>
    <p>{{text}}</p>
    <slot>

    </slot>
    <img :src="images" alt="">
  </div>
</template>

<script>
export default {
  name:'etapesTarifs',
  props:['text','num','images']
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "./scss/_variables.scss";
.etapesTarifs{
  display: flex;
  flex-direction: row;
  min-height: 20vh;
  align-items: center;
  background-color: $blanchatre;
  justify-content: space-between;
  padding: 0 30px;
  border-radius: 30px;
  width: 60%;
  margin: 10px auto;
  img{
    max-height: 20vh;
  }
  .num{
    $size:50px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: $size;
    width: $size;
    background-color: $fancy;
    font-size: 25px;
    font-weight: bolder;
    color: $blanchatre;
    border-radius: 100%;
    margin: 0 50px ;
  }
  p{
    font-size: $fontsize;
    font-weight: bolder;
    text-align: center;
  }
}
@media (max-width:1200px) {
  .etapesTarifs{
    width: 90%;
  }
}
@media (max-width:600px) {
  .etapesTarifs{
    flex-direction: column;
    width: 70%;
    .num{
      margin: 20px auto;
    }
    p{
      margin: 20px auto;
    }
  }
}
</style>
