<template>
  <div class="question">
      <div @click="ckicked = !ckicked" :class="[{'selected': Selected} ,'quest']">
          <h2> {{question}} </h2>
      </div>
      <div class="reponse">
          <hr>
          <p> {{reponse}} </p>
          <slot>

          </slot>
      </div>
  </div>
</template>

<script>
export default {
  name:'etapesTarifs',
  props:['question','reponse'],
  data() {
    return {
      ckicked:false
    }
  },
  computed:{
    Selected(){
      return this.ckicked
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./scss/_variables.scss";
.question{
  width: 80%;
  margin: 10px auto;
  background-color: $blanchatre;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  
  .quest{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    &:hover{
      opacity: 0.8;
    }
    h2{
      margin-right: 70px;
    }
    &::after{
      content: "+";
      font-size: 40px;
    }
  }
  .reponse{
    width: 80%;
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    hr{
      margin: 20px 0;
      height: 2px;
      background: $grey;
      border: none;
      width: 50%;
    }
    p{
      margin: 20px auto;
    }
  }
  .selected{
    &::after{
      content: "-";
      font-size: 50px;
    }
    & + .reponse{
      display: flex;
    }
  }
}
@media (max-width:850px) {
  .question{
    width: 95%;
  }
}
</style>