<template>
    <div class="gestion-carton">
        <h2>Gestion des Cartons</h2>
        <table>
            <thead>
                <th>name</th>
                <th>unit</th>
                <th>prixUnit</th>
                <th>quantiteMax</th>
                <th>port</th>
                <th>Id</th>
                <th></th>
                <th></th>
            </thead>
            <tbody>
                <tr v-for="carton in cartons" :key="carton">
                    <td data-label="name"
                        v-if="carton.id != rowToEdit.row"
                    >{{ (carton.name == null || carton.name == '') ? ' - ' : carton.name }}</td>
                    <td data-label="unit"
                        v-if="carton.id != rowToEdit.row"
                    >{{ (carton.unit == null || carton.unit == '') ? ' - ' : carton.unit }}</td>
                    <td data-label="prixUnit"
                        v-if="carton.id != rowToEdit.row"
                    >{{ (carton.prixUnit == null || carton.prixUnit == '') ? ' - ' : carton.prixUnit }}</td>
                    <td data-label="quantiteMax"
                        v-if="carton.id != rowToEdit.row"
                    >{{ (carton.quantiteMax == null || carton.quantiteMax == '') ? ' - ' : carton.quantiteMax }}</td>
                    <td data-label="port" v-if="carton.id != rowToEdit.row">{{ carton.port }}</td>
                    <td data-label="Id"
                        v-if="carton.id != rowToEdit.row"
                    >{{ (carton.id == null || carton.id == '') ? ' - ' : carton.id }}</td>
                    <td data-label="Modiffier" v-if="carton.id != rowToEdit.row">
                        <button @click="Edith(carton)">
                            <ion-icon name="create"></ion-icon>
                        </button>
                    </td>
                    <td data-label="Supprimer" v-if="carton.id != rowToEdit.row">
                        <button @click="supprimer(carton)">
                            <ion-icon name="trash"></ion-icon>
                        </button>
                    </td>
                    <!-- Edtith zonne -->
                    <td data-label="name" v-if="carton.id == rowToEdit.row">
                        <input type="text" v-model="rowToEdit.name" />
                    </td>
                    <td data-label="unit" v-if="carton.id == rowToEdit.row">
                        <input type="number" v-model="rowToEdit.unit" />
                    </td>
                    <td data-label="prixUnit" v-if="carton.id == rowToEdit.row">
                        <input type="number" v-model="rowToEdit.prixUnit" />
                    </td>
                    <td data-label="quantiteMax" v-if="carton.id == rowToEdit.row">
                        <input type="number" v-model="rowToEdit.quantiteMax" />
                    </td>
                    <td data-label="port" v-if="carton.id == rowToEdit.row">
                        <input type="number" v-model="rowToEdit.port" />
                    </td>
                    <td data-label="Id" v-if="carton.id == rowToEdit.row">{{ carton.id }}</td>
                    <td data-label="Valider" v-if="carton.id == rowToEdit.row">
                        <button @click="update()">Ok</button>
                    </td>
                    <td data-label="" v-if="carton.id == rowToEdit.row"></td>
                </tr>
            </tbody>
        </table>
        <form method="post">
            <div class="same-row">
                <div class="col">
                    <label for>Name</label>
                    <input required type="text" name="name" v-model="addCarton.name" />
                </div>
                <div class="col">
                    <label for>Image</label>
                    <input disabled type="text" name="img" v-model="addCarton.img" />
                </div>
            </div>
            <div class="same-row">
                <div class="col">
                    <label for>Unit</label>
                    <input type="number" name="unit" v-model="addCarton.unit" />
                </div>
                <div class="col">
                    <label for>PrixUnit</label>
                    <input type="number" name="prixUnit" v-model="addCarton.prixUnit" />
                </div>
            </div>
            <div class="same-row">
                <div class="col">
                    <label for>quantiteMax</label>
                    <input type="number" name="quantiteMax" v-model="addCarton.quantiteMax" />
                </div>
                <div class="col">
                    <label for>port</label>
                    <input type="number" name="port" v-model="addCarton.port" />
                </div>
            </div>

            <button @click="ajout()">Ajouter</button>
        </form>
    </div>
</template>

<script>
export default {
    name: 'Admin',
    data() {
        return {
            cartons: [],
            rowToEdit: {
                name: '',
                unit: 0,
                prixUnit: 5,
                quantiteMax: 1,
                port: 0
            },
            addCarton: {
                name: '',
                img: 'carton-1.png',
                unit: 88,
                prixUnit: 5,
                quantiteMax: 1,
                port: 0,
            }
        }
    },
    methods: {
        Edith(carton) {
            let rowToEdit = {
                row: carton.id,
                name: (carton.name == null) ? '' : carton.name,
                unit: (carton.unit == null) ? '' : carton.unit,
                prixUnit: (carton.prixUnit == null) ? '' : carton.prixUnit,
                quantiteMax: (carton.quantiteMax == null) ? '' : carton.quantiteMax,
                port: (carton.port == null) ? '' : carton.port,
            }
            this.rowToEdit = rowToEdit
        },
        update() {

            const api = require('../../api')
            let auth = api.getCookie('adminAuth')

            var myHeaders = new Headers();
            myHeaders.append("Authorization", "Bearer " + auth);
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

            var urlencoded = new URLSearchParams();
            urlencoded.append("name", this.rowToEdit.name);
            urlencoded.append("unit", this.rowToEdit.unit);
            urlencoded.append("prixUnit", this.rowToEdit.prixUnit);
            urlencoded.append("quantiteMax", this.rowToEdit.quantiteMax);
            urlencoded.append("port", this.rowToEdit.port);

            var requestOptions = {
                method: 'PUT',
                headers: myHeaders,
                body: urlencoded,
                redirect: 'follow'
            };

            fetch("https://www.aventbirthday-pro.fr/api/cartons/" + this.rowToEdit.row, requestOptions)
                .then(response => response.text())
                .then(result => {
                    console.log(result);
                    if (!result.error) {
                        this.rowToEdit = {
                            row: 0,
                            name: '',
                            unit: 0,
                            prixUnit: 5,
                            quantiteMax: 1,
                            port: 0
                        }
                        window.location.replace("/admin");
                    }
                })
                .catch(error => console.log('error', error));
        },
        supprimer(carton) {

            const api = require('../../api')
            let auth = api.getCookie('adminAuth')

            var myHeaders = new Headers();
            myHeaders.append("Authorization", "Bearer " + auth);

            var urlencoded = new URLSearchParams();

            var requestOptions = {
                method: 'DELETE',
                headers: myHeaders,
                body: urlencoded,
                redirect: 'follow'
            };

            fetch("https://www.aventbirthday-pro.fr/api/cartons/" + carton.id, requestOptions)
                .then(response => response.json())
                .then(() => {
                    window.location.replace("/admin")
                })
                .catch(error => console.log('error', error));
        },
        ajout() {
            const api = require('../../api')
            let auth = api.getCookie('adminAuth')

            var myHeaders = new Headers();
            myHeaders.append("Authorization", "Bearer " + auth);
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

            var urlencoded = new URLSearchParams();
            urlencoded.append("name", this.addCarton.name);
            urlencoded.append("img", this.addCarton.img);
            urlencoded.append("unit", this.addCarton.unit);
            urlencoded.append("prixUnit", this.addCarton.prixUnit);
            urlencoded.append("quantiteMax", this.addCarton.quantiteMax);
            urlencoded.append("port", this.addCarton.port);
            urlencoded.append("active", true);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: urlencoded,
                redirect: 'follow'
            };

            fetch("https://www.aventbirthday-pro.fr/api/cartons/register/", requestOptions)
                .then(response => response.text())
                .then(() => {
                    window.location.replace("/admin")
                })
                .catch(error => console.log('error', error));
        },
        errorState(e) {
            this.$emit('error-state', e)
        }
    },
    computed: {
        checkPasswd() {
            return this.addcarton.passWord == null || this.addcarton.passWord == ''
        }
    },
    components: {
    },
    created() {
        const api = require('../../api')
        let auth = api.getCookie('adminAuth')
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + auth);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch("https://www.aventbirthday-pro.fr/api/cartons", requestOptions)
            .then(response => response.json())
            .then(result => {
                this.cartons = result
            })
            .catch(error => console.log('error', error));
    }
}
</script>


<style lang="scss" scoped>
@import "./scss/_variables.scss";
form {
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    margin: 20px auto;
    button {
        margin: 30px 0;
    }
}
.gestion-carton {
    margin: 20px 0;
    background-color: $blanchatre;
    padding: 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    h2 {
        margin: 20px 0 50px 0;
    }
}
table {
    background-color: $fancy;
    border-radius: 5px;
    width: 95%;
    thead th {
        padding: 5px 8px;
    }
    tbody tr {
        text-align: center;
        td {
            margin: 2px 3px;
            padding: 0 5px;
            border-radius: 2px;
            background: white;
        }
    }
}
.multiusage {
    ion-icon {
        font-size: 30px;
    }
    .green {
        color: green;
    }
    .red {
        color: red;
    }
}
.checkbox {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 20px 0;
}
.same-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 10px;
    justify-content: center;
    align-items: center;
}
.col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 5px 10px;
    label {
        margin: 5px 0;
        font-weight: bolder;
    }
    input {
        padding: 7px;
        border-radius: 20px;
        border: solid $grey 1px;
        font-size: $fontsize;
        color: $fontcolor;
        outline: none;
        &::placeholder {
            color: $grey;
            font-weight: bold;
        }
        &:focus-visible {
            border: solid 2px $focuscolor;
        }
    }
}
.col .isBad {
    border: solid $bad 2px;
}
.col .isCorrect {
    border: solid $fancy 2px;
}

button {
    padding: 5px 10px;
    font-size: $fontsize;
        ion-icon {
        color: white;
        font-size: $fontsize;
    }
}

@media screen and (max-width: 1250px) {
  .gestion-user{
    width: 90%;
  }
  table {
    border: 0;
  }
  
  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  
  table tr {
    border-bottom: 3px solid #ddd;
    margin-bottom: .625em;
    overflow: auto;
  }
  
  table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: $fontsize;
    text-align: right;
  }
  
  table td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }
  
  table td:last-child {
    border-bottom: 0;
  }
}
</style>