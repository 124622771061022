<template>
  <div class="CGV">
    <h1>Conditions générales de vente</h1>
    <p>
      <b>Article 1 : CHAMP D’APPLICATION :</b>
      <br />Les présentes Conditions Générales de Vente (ci-après désignées « CGV ») s’appliquent aux ventes de
      produits entre la société SARL AVENTBIRTHDAY (ci-après désigné « La Société »), Société à
      responsabilité limitée au capital de 5 000 euros, immatriculée au RCS de MONTPELLIER sous le
      numéro 813 028 370 et ayant son siège social 97, Rue du Merlot, 34270 LES MATELLES, France et tout professionnel effectuant un achat sur le site Internet marchand www.aventbirthday-pro.fr, ci-
      après désigné « le Client ».
      <br />
      <br />La Société a pour objet la vente en boutique et sur internet de calendriers d’articles et d’accessoires
      divers d’anniversaires de fête et d’animation pour adultes et enfants.
      Le Client déclare être majeur ou titulaire d’une autorisation parentale et disposer de la capacité
      juridique à contracter lui permettant d’effectuer une commande sur le Site.
      Le Client déclare avoir pris connaissance et avoir accepté les présentes CGV, accessibles aisément sur
      le Site.
      <br />Les présentes CGV sont applicables pour tous nos produits (ci-après les Produits) et pour l’ensemble
      de nos Clients.
      <br />La Société réserve la vente des Produits sur le Site aux Client situés en France métropolitaine et en
      Corse.
      <br />
      <br />Toute commande de Produits sur le Site implique l’engagement préalable du Client d’avoir pris
      connaissance des présentes CGV et de les avoir expressément acceptées.
      Les Parties conviennent que leurs relations sont régies exclusivement par les présentes CGV.
      Ces CGV peuvent faire l’objet de modifications. Dès lors, il est entendu que les CGV applicables sont
      celles en vigueur sur le Site au jour de la passation de la commande.
      Le Client a la possibilité d’imprimer les présentes CGV et de les télécharger sous format PDF en
      cliquant ici.
      <br />
      <br />
      <b>Article 2 : CARACTERISTIQUES DES BIENS :</b>
      <br />
      <br />Tous les efforts ont été faits pour s’assurer de l’exactitude des informations présentées sur LE SITE
      VENDEUR disponible à l’adresse www.aventbirthday.fr. LE VENDEUR ou ses fournisseurs ne sont
      néanmoins pas responsables des conséquences, incidents, dommages spéciaux résultant des
      transmissions électroniques ou de l’exactitude de l’information transmise même dans le cas où LE
      VENDEUR ait eu connaissance de la possibilité de tels dommages.
      <br />
      <br />
      <b>Article 3 : DUREE DE VALIDITE DES OFFRES :</b>
      <br />Les offres de Produits de la Société sont valables aussi longtemps qu’elles sont visibles sur le Site,
      sauf opérations spéciales dont la durée de validité est spécifiée sur le Site.
      Le Client est invité à consulter la disponibilité des Produits sur la page d’information de chaque
      Produit. La société AVENTBIRTHDAY se réserve la possibilité de cesser la commercialisation de tout
      Produit et/ou de modifier à tout moment les caractéristiques de ses Produits, sans aucun préavis et
      sans que cette modification n’ouvre droit au versement de quelconques dommages-intérêts.
      <br />
      <br />
      <b>Article 4 : PRIX – PAIEMENT DU PRIX :</b>
      <br />Le Client, par la validation de sa commande, a une parfaite connaissance de son obligation de
      paiement à la Société AVENTBIRTHDAY des produits dont il a fait l’achat.
      Le Client s’engage à s’assurer de sa solvabilité avant toute Commande. Seul le paiement comptant
      est accepté dans le cadre des présentes CGV.
      <br />
      <br />PRIX
      Les produits sont fournis au prix en vigueur au moment de la passation de la commande.
      Toute commande passée sur le site est exprimée et payable en Euros. Les prix indiqués incluent la
      TVA au taux en vigueur au jour de la passation de la commande. Ils comprennent également les frais
      de livraison.
      <br />
      <br />PAIEMENT <br>
      Le paiement s’effectue soit en ligne par carte bancaire lors de la passation de commande sur le site
      web, soit par virement à l’ordre de la SARL AVENTBIRTHDAY adressé au 97, Rue du Merlot, 34270 LES
      MATELLES et accompagné de la confirmation de commande qui vous sera adressée par e-mail
      précisant le détail de votre commande.
      <br />Dans le cas d’un paiement par carte, le client s’engage à n’utiliser que des cartes bancaires dont il est
      titulaire et dont le compte est suffisamment approvisionné pour satisfaire la commande.
      La commande validée par le client ne sera considérée comme effective que lorsque les centres de
      paiement bancaires concernés auront donné leur accord. En cas de refus desdits centres, la
      commande sera automatiquement annulée et le client sera prévenu, selon le cas, par courriel ou par
      message texte sur son téléphone.
      <br />Le paiement par carte bancaire est parfaitement sécurisé et sera effectué sur le serveur sécurisé de
      notre banque.
      <br />La SARL AVENTBIRTHDAY n’a jamais accès aux informations personnelles et confidentielles relatives
      au moyen de paiement.
      <br />
      <br />
      <b>Article 5 : DISPONIBILITE :</b>
      <br />Nous affichons la disponibilité des produits que nous vendons sur le Site Internet sur chaque fiche
      produit. Nous ne pouvons apporter plus de précision concernant la disponibilité des produits que ce
      qui est indiqué sur ladite page ou ailleurs sur le Site Internet.
      <br />Lors du traitement de votre commande, nous vous informerons dès que possible par courrier
      électronique en utilisant l’adresse que vous aurez renseignée si des produits que vous avez
      commandés s’avèrent être indisponibles, et nous ne vous facturerons pas ces produits.
      <br />
      <br />
      <b>Article 6 : MODALITES DE PASSATION DE LA COMMANDE :</b>
      <br />La validation de la Commande passée par le Client sur le Site est soumise au respect du process
      décrit ci-dessous :
      <br />Passation de la commande :
      <br />Vous pouvez passer commande sur internet directement sur le site www.aventbirthday-pro.fr :
      <br />Pour ce faire vous devez cliquer sur la rubrique « commander » puis sélectionner la quantité de
      produits objet de la commande après avoir pris connaissance de l’ensemble des informations
      précontractuelles, techniques et commerciales ;
      Après avoir sélectionné les produits et renseigné la quantité souhaitée, vous devrez cliquer sur
      l’onglet « suivant ».
      <br />Une fois votre sélection terminée vous devez cliquer dans l’onglet « suivant ». Vous pouvez modifier
      votre panier en cliquant sur l’onglet « precedent ». Dès que votre panier correspond à votre souhait,
      vous devez cliquer sur « valider ».
      <br />Ensuite, vous devrez suivre les indications relatives à votre identification, votre adresse de
      facturation et de livraison, les champs obligatoires étant indiqués.
      <br />
      <br />Validation et confirmation de la commande :
      <br />Pour valider votre commande, vous devez indiquer votre mode de règlement, soit par virement, soit
      par carte bancaire après avoir accepté les présentes conditions générales de vente.
      Le Client reconnait que le « double clic » associé à la case à cocher sur la prise de connaissance des
      CGV vaut consentement à contracter, constitue une acceptation irrévocable et sans réserve des CGV
      et la parfaite reconnaissance de son obligation de paiement de la Commande.
      Le fait de valider votre commande implique l’obligation pour vous de payer le prix indiqué. Toute
      commande figurant sur le site Internet suppose l’adhésion aux présentes CGV.
      L’ensemble des données fournies et la confirmation enregistrée vaudront preuve de la transaction.
      La confirmation de commande vaudra signature et acceptation des opérations effectuées. Vous
      déclarez en avoir parfaite connaissance.
      <br />
      <br />Un récapitulatif des informations de votre commande et des présentes Conditions Générales, vous
      sera communiqué via l’adresse e-mail de confirmation de votre commande.
      Les informations contractuelles seront présentées en langue française.
      La société AVENTBIRTHDAY se réserve le droit de ne pas enregistrer un paiement, et de ne pas
      confirmer une commande pour quelque raison que ce soit, et plus particulièrement en cas de
      problème d’approvisionnement, ou en cas de difficulté concernant la commande reçue, sans
      indemnité pour le Client.
      <br />
      <br />
      <b>Article 7 : CODES PROMO :</b>
      <br />L’utilisation du code promo est soumise à l’acceptation préalable des présentes Conditions générales
      de vente.
      <br />Pour utiliser votre code promo, il vous suffit lors de votre nouvel achat, d’indiquer, sur la page
      récapitulative de votre commande, le code dans la partie « Codes promo » puis il sera pris en compte
      automatiquement
      La remise sera automatiquement déduite du prix total de votre commande.
      <br />Les codes promo ne peuvent être convertis en une somme remboursable ou payable au Client.
      Ils ne sont pas cumulables entre eux, ni fractionnables et ne peuvent être utilisés qu’avec un mode
      de paiement par carte bancaire ou virement .
      <br />Les codes promos utilisables sur le site peuvent être obtenus via les différentes opérations
      commerciales mises en place par la société AVENTBIRTHDAY.
      <br />
      <br />Modalités d’utilisation des « codes promos » :
      <br />leur durée de validité dépend de l’opération commerciale mise en place par la société
      AVENTBIRTHDAY, laquelle sera indiquée au consommateur soit à l’occasion de la transmission de son
      code promo, soit au plus tard, au moment de la commande du produit,
      ils ne sont pas sécables, et ne peuvent donc être utilisés que pour un seul achat en ligne,
      Ils ne sont pas cumulables,
      ils disposent d’un code informatique à usage unique.
      Sauf disposition contraire, ils sont valables sur tout le site, hors produits en promotion ou en soldes
      (périodes légales).
      <br />ils sont utilisables sur PC, mobile et tablette.
      <br />
      <br />
      <b>Article 8 : LIVRAISON :</b>
      <br />
      <br />Zone géographique :
      <br />Les produits sont livrés uniquement en France métropolitaine, la Corse en faisant partie.
      <br />
      <br />Délai de livraison :
      <br />Les produits sont expédiés dès réception du paiement et livrés par transporteur dans un délai de 72
      heures maximum, à compter:
      <br />En cas de paiement en ligne par carte bancaire : de la confirmation de votre commande qui vous sera
      adressée par mail à l’issue du processus de passation de la commande décrit ci- dessus,
      En cas de paiement par virement bancaire : de la réception de virement à la condition que celui-ci
      soit accompagné de la confirmation de commande qui vous sera adressée par e-mail précisant le
      détail de votre commande.
      <br />
      <br />Frais de port :
      <br />Les frais de port sont inclus dans le prix TTC indiqué sur le site et variables selon la quantité de
      produits commandés.
      <br />
      <br />Modalités de livraison/Suivi :
      <br />En cas de commande d’un seul produit, celui-ci est adressé par lettre suivie.
      En cas de commande de plusieurs produits, ils vous seront adressés par colis suivi.
      Vous pourrez dès lors suivre votre lettre ou colis en vous connectant sur le site internet de la poste
      ou autre transporteur et en renseignant le numéro qui vous sera communiqué dès l’expédition de
      votre commande.
      <br />
      <br />
      <b>Article 9 : FORCE MAJEURE :</b>
      <br />La SARL AVENTBIRTHDAY ne peut être considérée comme ayant failli à son obligation de livraison ou
      de mise à disposition dès lors que son inexécution est retardée, entravée ou empêchée pour cause
      de rupture de stock, d’indisponibilité du produit, ou de force majeure.
      <br />Sont considérés comme cas fortuits ou de force majeure tous faits ou circonstances irrésistibles,
      extérieurs aux parties, imprévisibles, inévitables, indépendants de la volonté de la SARL
      AVENTBIRTHDAY et qui ne peuvent être évités par elle malgré tous les efforts raisonnablement
      possibles.
      <br />De façon expresse, sont notamment considérés comme cas fortuits ou de force majeure, outre ceux
      habituellement retenus par la jurisprudence des cours et tribunaux français : blocage des moyens de
      transport ou d’approvisionnement, guerre, émeute, incendie, tremblements de terre, tempêtes,
      <br />
      <br />inondation, foudre, arrêt ou difficultés des réseaux de télécommunication, grèves, accidents ouproblèmes techniques relatifs aux outils de travail.
      <br />
      <br />
      <b>Article 10 : RESERVE DE PROPRIETE :</b>
      <br />La société AVENTBIRTHDAY se réserve la propriété de la marchandise livrée jusqu’au complet
      paiement du prix par le Client.
      <br />
      <br />
      <b>Article 11 : QUALITE / DUREE DE VALIDITE DU PRODUIT :</b>
      <br />La SARL AVENTBIRTHDAY garantit au Client que les Produits sont fabriqués et emballés selon des
      règles de qualités strictes, et conservées conformément aux normes en vigueur en France.
      La SARL AVENTBIRTHDAY ne saurait garantir la qualité de ses Produits si le Client n’a pas respecté des
      conditions de conservation optimum, ou si celui-ci a consommé des produits au-delà de la date limite
      de consommation indiquée sur l’emballage.
      Le client est informé de la date limite de consommation ou de durabilité minimale, laquelle est
      indiquée sur le produit.
      <br />
      <br />
      <b>Article 12 : RECEPTION / RETOURS :</b>
      <br />Le client doit refuser de réceptionner la livraison si les produits ont été endommagés, sont
      manquants ou si le paquet a été ouvert ou reconditionné. Le client doit alors exprimer expressément
      et de manière détaillée ses réserves sur le bordereau de livraison, devant le transporteur ou ses
      employés.
      <br />Le client devra ensuite envoyer une lettre recommandée avec accusé de réception au transporteur
      reprenant les réserves exprimées dans les 48 heures suivant la livraison. Une copie de ce courrier
      doit être transmise à la SARL AVENTBIRTHDAY dans le même délai.
      La SARL AVENTBIRTHDAY n’est pas responsable de la livraison, les réserves à ce titre devant être
      adressées au transporteur. Cependant, elle reste à la disposition du client pour lui offrir son
      assistance dans le cadre de ces démarches.
      <br />Dès lors que la réception des biens est effectuée sans réserve, ils sont réputés avoir été livrés en bon
      état et dans leur intégralité.
      <br />Pour toutes les réclamations étrangères à la livraison et relatives à l’état des marchandises, leur
      quantité, leur qualité ou leur référence erronée par rapport à l’offre, le client doit envoyer au
      vendeur un courrier électronique accompagné de tout justificatif démontrant le bien-fondé de sa
      réclamation dans les 48 heures suivant la livraison.
      A défaut, aucune réclamation ne sera recevable.
      Les produits doivent être retournés dans leur emballage d’origine en bon état, avec tous les produits
      et accessoires ainsi que toutes les étiquettes afin de permettre une commercialisation à l’état neuf.
      <br />
      <br />Le remboursement est notamment subordonné à la réception par la SARL AVENTBIRTHDAY des biens
      et produits retournés par le client dans les conditions décrites ci-dessus.
      <br />
      <br />

      <b>Article 13 : MODIFICATIONS DES CONDITIONS GENERALES DE VENTE :</b>
      <br />La SARL AVENTBIRTHDAY peut modifier les présentes conditions générales de vente sans préavis.
      Les conditions générales de vente applicables seront celles en vigueur au jour de la commande
      effectuée par le client.
      <br />
      <br />

      <b>Article 14 : PROPRIETE INTELLECTUELLE :</b>
      <br />L’ensemble du contenu de ce site est la propriété d’AVENTBIRTHDAY
      Tous droits réservés.
      <br />Les logos, marques et visuels cités sur ce site appartiennent à leurs propriétaires respectifs et sont
      déposés auprès de l’Institut National de la Propriété Industrielle.
      <br />Propriété intellectuelle : Le concept dénommé « AventBirthday » est intrinsèque à la création
      littéraire et/ou artistique de M. Vincent Guicheteau qui est intitulée « Adventus Evenire» (Éditions
      USD System – Collection « Passeport Intellectuel CB » N° VG12943FR160421. La réalisation technique
      et/ou commerciale des procédés et savoir-faire du concept – obligeant la production, la reproduction
      © et l’interprétation de tout ou partie des textes et des dessins qui sont consignés dans l’ouvrage sus
      désigné – est constitutive des produits qui sont exposés dans ce site Internet, ainsi que des projets
      commerciaux et économiques qui s’y rattachent.
      <br />
        <a href="copyright-aventbirthday.pdf" target="_blanck2">
        Certificat copyright N°1132571</a>
      <br />Rappel : Du seul fait de la création de son œuvre dont il est l’unique propriétaire, l’auteur détient
      l’exclusivité mondiale de tout ou partie de sa production, de sa reproduction © et de son
      interprétation et ce, relativement à l’exploitation commerciale du concept matérialisé qui en résulte.
      Conformément aux conventions internationales et aux lois internes des États sur le droit d’auteur,
      cette exclusivité est mondialement reconnue pour toute la vie de l’auteur ainsi que cinquante à
      soixante-dix ans après son décès et ce, selon la législation de chaque État.
      Tout le contenu du site web est et reste la propriété intellectuelle et exclusive de la SARL
      AVENTBIRTHDAY. Tous les droits lui sont réservés. Personne n’est autorisé à reproduire, exploiter,
      rediffuser ou utiliser à quelque titre que ce soit, même partiellement, des éléments du site qu’ils
      soient logiciels, visuels ou sonores. Tous ces éléments sont protégés.
      <br />Tout lien simple ou par hypertexte est strictement interdit sans un accord écrit exprès de la société
      AVENTBIRTHDAY.
      <br />« AVENTBIRTHDAY » est une marque enregistrée à l’INPI le 5 juin 2015, sous le numéro 4186256.
      <br />
      <br />

      <b>Article 15 : DONNES PERSONNELLES :</b>

      <br />
      <br />La SARL AVENTBIRTHDAY est responsable du traitement de données à caractère personnel. En cette
      qualité, elle a déclaré les traitements de données à caractère personnel qu’elle met en œuvre à la
      Commission Nationale de l’Informatique et des Libertés (CNIL).
      <br />La société AVENTBIRTHDAY se réserve le droit de collecter les informations nominatives et les
      données personnelles vous concernant. Elles sont nécessaires à la gestion de votre commande, ainsi
      qu’à l’amélioration des services et des informations que nous vous adressons.
      <br />Elles peuvent aussi être transmises aux sociétés qui contribuent à ces relations, telles que celles
      chargées de l’exécution des services et commandes pour leur gestion, exécution, traitement et
      paiement.
      <br />Ces informations et données sont également conservées à des fins de sécurité, afin de respecter les
      obligations légales et réglementaires.
      <br />Conformément à la loi du 6 janvier 1978, vous disposez d’un droit d’accès, de rectification et
      d’opposition aux informations nominatives et aux données personnelles vous concernant,
      directement sur le site Internet.
      <br />La sécurité et la confidentialité des données personnelles comptent parmi les préoccupations
      majeures de la SARL AVENTBIRTHDAY. Elle s’engage à prendre toutes précautions utiles au regard de
      la nature des données et des risques présentés par le traitement pour préserver la sécurité des
      données et, notamment, empêcher qu’elles soient déformées, endommagées, ou que des tiers non
      autorisés y aient accès.
      <br />Le client peut à tout moment exercer son droit d’accès et de rectification en écrivant à la SARL
      AVENTBIRTHDAY – 97 Rue du Merlot – 34270 LES MATELLES et en joignant au courrier une copie de
      sa pièce d’identité.
      <br />En utilisant le site web et en contractant avec la SARL AVENTBIRTHDAY, le client consent au
      traitement de ses données personnelles. Néanmoins, il peut s’opposer à tout moment, pour des
      raisons légitimes, à ce que des données à caractère personnel le concernant fassent l’objet d’un
      traitement en écrivant à la SARL AVENTBIRTHDAY – 97 Rue du Merlot – 34270 LES MATELLES et en
      joignant au courrier une copie de sa pièce d’identité.
      <br />
      <br />
      <b>Article 18 : DIVISIBILITE DES CLAUSES :</b>
      <br />Si une ou plusieurs stipulations du présent contrat sont tenues pour non valides ou déclarées telles
      en application d’une loi, d’un règlement ou à la suite d’une décision définitive d’une juridiction
      compétente, les autres stipulations conserveront leur pleine validité sauf si elles présentent un
      caractère indissociable avec la disposition non valide.
      <br />En cas de difficulté ou de différend entre les parties à l’occasion du présent accord, les parties
      conviennent de rechercher une solution amiable dans l’esprit de cet accord.
      <br />
      <br />

      <b>ARTICLE 16 : LOI APPLICABLE ET JURIDICTION COMPETENTE :</b>
      Les achats effectués sur le site internet sont réputés avoir été réalisés en France. La loi française
      gouverne tous les aspects des contrats relatifs aux commandes passées depuis ce site.
    </p>
  </div>
</template>

<script>
export default {
  name: 'CGV',
  data() {
    return {

    }
  },
  components: {
  },
  methods: {
  },
  created() {
    const api = require('../../api')
    api.checkCookie()
  },
}
</script>

<style lang="scss" scoped>
@import "./scss/_variables.scss";
p {
  font-weight: 500;
}
.CGV{
  width: 80%;
  margin: 50px auto;
}
a {
  color: $fancy;
  font-weight: bolder;
}
h1 {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-flow: row wrap;
  margin: 50px 0;
  ion-icon {
    margin-left: 20px;
    font-size: 40px;
  }
}
</style>