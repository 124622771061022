<template>
  <div class="Notvalide">
    <h1>Oups ! <br> Il semble y avoir un problème avec la commande en cours !</h1>
    <button @click="redirectPage('tarif')">Retour au site </button>
  </div>
</template>

<script>
export default {
  name: 'Notvalide',  
  data() {
      return {

      }
  },
  components:{
  },
  methods:{
    redirectPage(page){
      window.location.replace("/"+page);
    }
  },
  created() {
    const api = require('../../api')
    api.checkCookie()
  },
}
</script>

<style lang="scss" scoped>
@import "./scss/_variables.scss";

h1{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-flow: row wrap;
    ion-icon{
        margin-left: 20px;
        font-size: 40px;
    }
}
.Notvalide{
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  min-height: 120vh;
  h1{
    margin: 50px auto;
    text-align: center;
  }
}
.infoBanque{
    text-align: center;
    margin: 50px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: $blanchatre;
    border-radius: 20px;
    padding: 20px;
    width: 50%;
    min-width: 300px;
}
  button{
      background-color: $fancy;
      color: $blanchatre;
    }

</style>