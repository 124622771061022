<template>
    <div class="information">
        <h1></h1>

        <div class="form">
            <div class="box-input">
                <h2>Informations personnelles</h2>
                <div class="input">
                    <div class="same-row">
                        <div class="col">
                            <label for>Nom</label>
                            <input
                                @keydown="testValue(datas.nom)"
                                @change="testValue(datas.nom)"
                                placeholder="Nom"
                                :class="{ 'isCorrect': datas.nom.status }"
                                required
                                v-model="datas.nom.value"
                                type="text"
                            />
                        </div>
                        <div class="col">
                            <label for>Prenom</label>
                            <input
                                @keydown="testValue(datas.prenom)"
                                @change="testValue(datas.prenom)"
                                :class="{ 'isCorrect': datas.prenom.status }"
                                placeholder="Prenom"
                                required
                                v-model="datas.prenom.value"
                                type="text"
                            />
                        </div>
                    </div>
                    <div class="same-row">
                        <div class="col">
                            <label for>Tel</label>
                            <input
                                @keydown="testValue(datas.tel)"
                                @change="testValue(datas.tel)"
                                :class="{ 'isCorrect': datas.tel.status }"
                                placeholder="06 51 XX XX XX"
                                required
                                v-model="datas.tel.value"
                                type="text"
                            />
                        </div>
                        <div class="col">
                            <label for>Enseigne</label>
                            <input
                                @keydown="testValue(datas.enseigne)"
                                @change="testValue(datas.enseigne)"
                                placeholder="Enseigne"
                                :class="{ 'isCorrect': datas.enseigne.status }"
                                v-model="datas.enseigne.value"
                                type="text"
                            />
                        </div>
                    </div>
                    <div class="same-row">
                        <div class="col" style="flex:1">
                            <label for>Mail</label>
                            <input
                                @keydown="testValue(datas.mail)"
                                @change="testValue(datas.mail)"
                                placeholder="mail"
                                :class="{ 'isCorrect': datas.mail.status }"
                                required
                                v-model="datas.mail.value"
                                type="mail"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="box-input">
                    <h2>Informations de livraison</h2>
                    <div class="input">
                        <div class="same-row">
                            <div class="col" style="flex:1">
                                <label for>Adresse 1</label>
                                <input
                                    @keydown="testValue(datas.livraison.addresse1)"
                                    @change="testValue(datas.livraison.addresse1)"
                                    :class="{ 'isCorrect': datas.livraison.addresse1.status }"
                                    placeholder="Adresse 1"
                                    v-model="datas.livraison.addresse1.value"
                                    type="text"
                                />
                            </div>
                            <div class="col" style="flex:1">
                                <label for>Adresse 2</label>
                                <input
                                    @keydown="testValue(datas.livraison.addresse2)"
                                    @change="testValue(datas.livraison.addresse2)"
                                    :class="{ 'isCorrect': datas.livraison.addresse2.status }"
                                    placeholder="Adresse 2"
                                    v-model="datas.livraison.addresse2.value"
                                    type="text"
                                />
                            </div>
                        </div>
                        <div class="same-row">
                            <div class="col">
                                <label for>ville</label>
                                <input
                                    @keydown="testValue(datas.livraison.ville)"
                                    @change="testValue(datas.livraison.ville)"
                                    placeholder="ville"
                                    :class="{ 'isCorrect': datas.livraison.ville.status }"
                                    v-model="datas.livraison.ville.value"
                                    type="text"
                                />
                            </div>
                            <div class="col">
                                <label for>Code Postal</label>
                                <input
                                    @keydown="testValue(datas.livraison.CP)"
                                    @change="testValue(datas.livraison.CP)"
                                    placeholder="Code Postal"
                                    :class="{ 'isCorrect': datas.livraison.CP.status }"
                                    v-model="datas.livraison.CP.value"
                                    type="text"
                                />
                            </div>
                        </div>
                        <div class="checkbox">
                            <label for="factu-id">Même adresse de facturation</label>
                            <input type="checkbox" v-model="sameval" id="factu-id" />
                        </div>
                    </div>
                </div>
                <div class="box-input" v-if="!sameval">
                    <h2>Informations de facturation</h2>
                    <div class="input">
                        <div class="same-row">
                            <div class="col">
                                <label for>Adresse 1</label>
                                <input
                                    @keydown="testValue(datas.facturation.addresse1)"
                                    @change="testValue(datas.facturation.addresse1)"
                                    :class="{ 'isCorrect': datas.facturation.addresse1.status }"
                                    placeholder="Adresse 1"
                                    v-model="datas.facturation.addresse1.value"
                                    type="text"
                                />
                            </div>
                            <div class="col">
                                <label for>Adresse 2</label>
                                <input
                                    @keydown="testValue(datas.facturation.addresse2)"
                                    @change="testValue(datas.facturation.addresse2)"
                                    :class="{ 'isCorrect': datas.facturation.addresse2.status }"
                                    placeholder="Adresse 2"
                                    v-model="datas.facturation.addresse2.value"
                                    type="text"
                                />
                            </div>
                        </div>
                        <div class="same-row">
                            <div class="col">
                                <label for>ville</label>
                                <input
                                    @keydown="testValue(datas.facturation.ville)"
                                    @change="testValue(datas.facturation.ville)"
                                    :class="{ 'isCorrect': datas.facturation.ville.status }"
                                    placeholder="ville"
                                    v-model="datas.facturation.ville.value"
                                    type="text"
                                />
                            </div>
                            <div class="col">
                                <label for>Code Postal</label>
                                <input
                                    @keydown="testValue(datas.facturation.CP)"
                                    @change="testValue(datas.facturation.CP)"
                                    :class="{ 'isCorrect': datas.facturation.CP.status }"
                                    placeholder="Code Postal"
                                    v-model="datas.facturation.CP.value"
                                    type="text"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="button">
            <button @click="Back">Retour</button>
            <button :disabled="!isCorrect" @click="Next">Suivant</button>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            sameval: true,
            datas: {
                nom: {
                    value: '',
                    status: false
                },
                prenom: {
                    value: '',
                    status: false
                },
                tel: {
                    value: '',
                    status: false
                },
                enseigne: {
                    value: '',
                    status: false
                },
                mail: {
                    value: '',
                    status: false
                },
                facturation: {
                    addresse1: {
                        value: '',
                        status: false
                    },
                    addresse2: {
                        value: '',
                        status: true
                    },
                    ville: {
                        value: '',
                        status: false
                    },
                    CP: {
                        value: '',
                        status: false
                    },
                },
                livraison: {
                    addresse1: {
                        value: '',
                        status: false
                    },
                    addresse2: {
                        value: '',
                        status: true
                    },
                    ville: {
                        value: '',
                        status: false
                    },
                    CP: {
                        value: '',
                        status: false
                    },
                }
            },
        }
    },
    methods: {
        Back() {
            this.$emit('GoBack')
        },
        Next() {
            const elem = this.datas
            let data = {
                delivery: {
                    nom: elem.nom.value,
                    prenom: elem.prenom.value,
                    tel: elem.tel.value,
                    mail: elem.mail.value,
                    addresse1: elem.livraison.addresse1.value,
                    addresse2: elem.livraison.addresse2.value,
                    CP: elem.livraison.CP.value,
                    ville: elem.livraison.ville.value,
                },
                factur: {
                    nom: elem.nom.value,
                    prenom: elem.prenom.value,
                    tel: elem.tel.value,
                    mail: elem.mail.value,
                    addresse1: (this.sameval) ? elem.livraison.addresse1.value : elem.facturation.addresse1.value,
                    addresse2: (this.sameval) ? elem.livraison.addresse2.value : elem.facturation.addresse2.value,
                    CP: (this.sameval) ? elem.livraison.CP.value : elem.facturation.CP.value,
                    ville: (this.sameval) ? elem.livraison.ville.value : elem.facturation.ville.value,
                },
                enseigne: elem.enseigne.value
            }
            this.$emit('Validation', data)
            console.log('next');
        },
        testValue(ctx) {
            switch (ctx) {
                case this.datas.mail:
                    ctx.status = ctx.value.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/)
                    break;
                case this.datas.tel:
                    ctx.status = ctx.value.match(/^((\+)33|0)[1-9](\d{2}){4}$/)
                    break;
                case this.datas.facturation.CP:
                    ctx.status = ctx.value.match(/^(?:0[1-9]|[1-8]\d|9[0-8])\d{3}$/)
                    break;
                case this.datas.livraison.CP:
                    ctx.status = ctx.value.match(/^(?:0[1-9]|[1-8]\d|9[0-8])\d{3}$/)
                    break;
                default:
                    ctx.status = ctx.value.length > 0
                    break;
            }
        }
    },
    computed: {
        isCorrect() {

            return this.datas.nom.status && this.datas.prenom.status && this.datas.tel.status && this.datas.enseigne.status && this.datas.mail.status && this.datas.livraison.addresse1.status && this.datas.livraison.addresse2.status && this.datas.livraison.ville.status && this.datas.livraison.CP.status && ((this.datas.facturation.addresse1.status && this.datas.facturation.addresse2.status && this.datas.facturation.ville.status && this.datas.facturation.CP.status) || this.sameval)
        }
    },
    created() {
        const api = require('../../api')
     api.checkCookie()
        let auth = api.getCookie('authentification')


        var requestOptions = {
            method: 'GET',
            headers: {
                "Authorization": "Bearer " + auth
            },
            redirect: 'follow'
        };

        fetch("https://www.aventbirthday-pro.fr/api/users", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (!result.error) {
                    let myinfo=result
                this.datas.enseigne={
                    value:myinfo.enseigne,
                    status:myinfo.enseigne.length>0
                }
                this.datas.mail={
                    value:myinfo.mail,
                    status:myinfo.mail.length>0
                }
                this.datas.tel={
                    value:myinfo.tel,
                    status:myinfo.tel.length>0
                }
                this.datas.livraison.addresse1={
                    value:myinfo.adresse,
                    status:myinfo.adresse.length>0
                }
                this.datas.livraison.ville={
                    value:myinfo.ville,
                    status:myinfo.ville.length>0
                }
                this.datas.livraison.CP={
                    value:myinfo.codePostal,
                    status:myinfo.codePostal.length>0
                }
                }
            })
            .catch(error => console.log('error', error));
    }
}
</script>

<style lang="scss" scoped>
@import "./scss/_variables.scss";
.information {
    background-color: $blanchatre;
    width: 95%;
    margin: 40px auto;
    padding: 20px 0;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h1 {
        margin: 10px 0;
    }
    .form {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
        align-items: center;
        width: 95%;
        margin: 20px 0;
    }
}

.box-input {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 20px;
    margin: 10px;
    h2 {
        margin: 10px;
    }
    .input {
        margin: 30px 20px;
        font-size: 20px;
        display: flex;
        flex-direction: column;
        .same-row {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin-top: 10px;
            justify-content: center;
            align-items: center;
        }
    }
}
.checkbox {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 20px 0;
}
.col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 5px 10px;
    label {
        margin: 5px 0;
        font-weight: bolder;
    }
    input {
        padding: 7px;
        border-radius: 20px;
        border: solid $grey 1px;
        font-size: $fontsize;
        color: $fontcolor;
        outline: none;
        &::placeholder {
            color: $grey;
            font-weight: bold;
        }
        &:focus-visible {
            border: solid 2px $focuscolor;
        }
    }
}
.col .isCorrect {
    border: solid $fancy 2px;
}
.button {
    display: flex;
    flex-direction: row;
    width: 50%;
    margin: 50px auto;
    justify-content: space-evenly;
    & > button:first-child {
        background-color: $grey;
        color: $fontcolor;
        margin-right: 10px;
    }
}
</style>