<template>
  <div class="commender">
    <h1>Page de commande</h1>

    <div class="step-section">
      <Stepper
        :steps="['Le volume', 'Composition', 'Information', 'Paiement']"
        :curentStep="curentstep"
        @change-step="changeStep"
      />
      <ChoixCarton v-if="showChoixCarton" @Validation="cartonValidee" />
      <Compose
        v-if="showChoixComposition"
        @GoBack="goBackStep"
        @validation="CompositionValidee"
        :total="quantite"
      />
      <Information v-if="showInformation" @GoBack="goBackStep" @validation="infoValidee" />
      <Payment
        v-if="showPayment"
        @GoBack="goBackStep"
        @validation="PaymentValidee"
        :detail="order.detail"
      />
    </div>
  </div>
</template>

<script>
import ChoixCarton from '../components/ChoixCarton.vue'
import Compose from '../components/Compose.vue'
import Information from '../components/Information.vue'
import Payment from '../components/Payment.vue'
import Stepper from '../components/stepper.vue'
export default {
  name: 'Commender',
  components: {
    ChoixCarton,
    Stepper,
    Compose,
    Information,
    Payment
  },
  methods: {
    changeStep(e) {
      console.log('step : ', e);
    },
    cartonValidee(e) {
      this.order.detail = e
      this.curentstep = 2
    },
    goBackStep() {
      this.curentstep--
    },
    CompositionValidee(e) {
      this.order.detail.products = e
      this.curentstep = 3
    },
    infoValidee(e) {
      this.order.delivery = e.delivery
      this.order.factur = e.factur
      this.order.enseigne = e.enseigne
      this.curentstep = 4
    },
    PaymentValidee(e) {
      this.order.detail.HT = e.HT
      this.order.detail.TTC = e.TTC
      this.order.detail.TVA = e.TVA
      this.order.detail.reduct = e.reduct
      this.order.detail.payment = e.payment
      this.order.detail.status = e.status
      const api = require('../../api')
      let auth = api.getCookie('authentification')

      var myHeaders = new Headers();
      myHeaders.append("Authorization", "Bearer " + auth);
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      var urlencoded = new URLSearchParams();
      urlencoded.append("detail", JSON.stringify(this.order.detail));
      urlencoded.append("livraison", JSON.stringify(this.order.delivery));
      urlencoded.append("facturation", JSON.stringify(this.order.factur));
      urlencoded.append("enseigne", this.order.enseigne);

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
      };

      fetch("https://www.aventbirthday-pro.fr/api/order/", requestOptions)
        .then(response => response.json())
        .then(result => {
          if (!result.error) {
            let date = new Date(Date.now() + 600000); // 10 min
            date = date.toUTCString();
            document.cookie = 'OrderToken=' + result.token + ';path=/;domain=www.aventbirthday-pro.fr;expires=' + date + ';samesite="strict";secure';
            //document.cookie = 'OrderToken=' + result.token + ';path=/;domain=localhost;expires=' + date + ';samesite="strict";secure';
            window.location.replace("https://www.aventbirthday-pro.fr/api/payment")
          }
        })
    }
  },
  data() {
    return {
      curentstep: 1,
      order: {
        detail: {},
      }
    }
  },
  computed: {
    showChoixCarton() {
      return this.curentstep <= 1
    },
    showChoixComposition() {
      return this.curentstep == 2
    },
    showInformation() {
      return this.curentstep == 3
    },
    showPayment() {
      return this.curentstep == 4
    },
    quantite() {
      return this.order.detail.quantite
    }
  },
  created() {
    const api = require('../../api')
    api.checkCookie()
  }
}
</script>

<style lang="scss" scoped>
@import "./scss/_variables.scss";

h1 {
  font-size: $fontsizeH1;
  margin: 50px 0;
  text-align: center;
}
</style>