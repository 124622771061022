<template>
    <div class="modal">
        <div class="close">
            <ion-icon @click="$emit('close')" name="close-sharp"></ion-icon>
        </div>
        <h2>détail de la commande de : <b>{{order.enseigne}}</b> </h2>
        <p>Paiment : {{order.detail.payment}}</p>
        <p>Prix HT : {{order.detail.HT}} € | Prix TTC: {{order.detail.TTC}} € | TVA : {{order.detail.TVA}}€</p>
        <p>Réduction : {{order.detail.reduct.code}} </p>
        <p>Carton : {{order.detail.carton.name}}</p>
        <div class="main-box">
            <div style="margin-left: 5px;">
                <p v-for="product in products" :key="product">
                    <span>{{product.name}} - {{product.count}}</span>
                </p>
                <p>Total - {{order.detail.quantite}}</p>
            </div>
            <div class="internal-box">
                <div>
                    <button class="custom-button" @click="showAdress=order.livraison">livraison</button>
                    <button class="custom-button" @click="showAdress=order.facturation">facturation</button>
                </div>
                <div v-if="showAdress_.CP">
                    <h3 v-if="showAdress==order.livraison">Livraison</h3>
                    <h3 v-if="showAdress==order.facturation">Facturation</h3>
                    <p>{{showAdress_.prenom}} {{showAdress_.nom}}</p>
                    <p>Adresse : {{showAdress_.addresse1}}  {{showAdress_.CP}} {{showAdress_.ville}} </p>
                    <p v-if="showAdress_.addresse2!=''">complement : {{showAdress_.addresse2}} </p>
                    <p>email : {{showAdress_.mail}} </p>
                    <p>tel : {{showAdress_.tel}} </p>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
export default {
    props:['order'],
    data(){
        return{
            showAdress:{}
        }
    },
    computed:{
        products(){
            return this.order.detail.products.filter(product => product.count>0)
        },
        showAdress_(){
            return this.showAdress
        }
    }
}
</script>
<style lang="scss" scoped>
    @import "./scss/_variables.scss";
    .modal{
        position: fixed;
        width: 90%;
        min-height: 100vh;
        margin: auto;
        top: 0;
        background-color: $blanchatre;
        border-radius: 2px;
        padding: 20px;
        z-index: 20;
        .close{
            position: relative;
            display: flex;
            flex-direction: row-reverse;
            font-size: 40px;
        }
        .main-box{
            display: flex;
            justify-content: space-between;
            .internal-box{
                width: 50%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                text-align: center;
            }
            .custom-button{
                padding: 5px;
                font-size: $fontsize;
                margin:  3px 5px;
            }
        }
    }
</style>