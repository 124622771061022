<template>
    <details id="selector" class="custom-select">
	<summary class="radios">
		<input :checked="defaultChoice(item,selectedChoice)" v-for="(item, index) in items" :key="index" type="radio" name="item" :id="index+'-nombre'" :title="item" >
	</summary>
	<ul class="list">
		<li v-for="(item, index) in items" :key="index" >
			<label :for="index+'-nombre'" @click="emitValue(item)">{{item}}</label>
		</li>
	</ul>
</details>
</template>

<script>
export default {
    props:['items','selectedChoice'],
    methods:{
        defaultChoice(v1,v2){
            return v1 == v2
        },
		emitValue(val)
		{
			document.getElementById('selector').attributes.removeNamedItem('open')
			this.$emit('newVal', val)
		},
    }
}
</script>

<style lang="scss" scoped>
@import "./scss/_variables.scss";

details {
	position: relative;
	width: 100px;
	margin-right: 1rem;
}

details[open] {
	z-index: 1;
}

summary {
	padding: 1rem;
	cursor: pointer;
	border-radius: 5px;
	background-color: $fancy;
    color: $blanchatre;
	list-style: none;
}

summary::-webkit-details-marker {
	display: none;
}

details[open] summary:before {
	content: '';
	display: block;
	width: 100vw;
	height: 100vh;
	background: transparent;
	position: fixed;
	top: 0;
	left: 0;
}

summary:after {
	content: '';
	display: inline-block;
	float: right;
	width: .5rem;
	height: .5rem;
	border-bottom: 1px solid currentColor;
	border-left: 1px solid currentColor;
	border-bottom-left-radius: 2px;
	transform: rotate(45deg) translate(50%, 0%);
	transform-origin: center center;
	transition: transform ease-in-out 100ms
}

summary:focus {
	outline: none;
}

details[open] summary:after {
	transform: rotate(-45deg) translate(0%, 0%);
}

ul {
	width: 100%;
	background: $fancy;
	position: absolute;
	top: calc(100% + .5rem);
	left: 0;
	padding: 1rem;
	margin: 0;
	box-sizing: border-box;
	border-radius: 5px;
	max-height: 200px;
	overflow-y: auto;
}

li {
	margin: 0;
	padding: 1rem 0;
	border-bottom: 1px solid $grey;
}

li:first-child {
	padding-top: 0;
}

li:last-child {
	padding-bottom: 0;
	border-bottom: none;
}

/* FAKE SELECT */


summary.radios {
	counter-reset: radios;
    color: $blanchatre;
}

input[type=radio] {
	counter-increment: radios;
	appearance: none;
	display: none;
}

input[type=radio]:checked {
	display: inline;
    color: $blanchatre;
}

input[type=radio]:after {
	content: attr(title);
	display: inline;
	font-size: 1rem;
}

ul.list {
	counter-reset: labels;
}

label {
	width: 100%;
	display: block;
	cursor: pointer;
    color: $blanchatre;
}

</style>