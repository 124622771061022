<template>
  <input style="display:none;" type="radio" name="carton-volume" :id="carton.id">
  <label @click="pushCarton(carton)" :for="carton.id" class="Carton-card" :title="prixUnit(carton)">
      <div class="imgsection">
          <img :src="imgCarton(carton.img)" :alt="carton.img">
           <slot>
          </slot>
         
      </div>
      <span class="name"> {{carton.name}} </span>
      <span class="unit"> {{carton.unit}} cal </span>
  </label>
</template>

<script>
export default {
  name: 'Carton-card',
  props:['carton'],
  data() {
      return {
          selected:false
      }
  },
  methods:{
      pushCarton(){
          this.$emit('UpCarton')
      },
      prixUnit(carton){
          return 'Prix unitaire : '+((carton.prixUnit * carton.unit+carton.port)/carton.unit).toFixed(2)+' €'
      },
      imgCarton(img){
          return 'cartons/'+img
      }
  },
  computed:{
      choose()
      {
          return this.selected
      }
  },
  created() {
  },
}
</script>

<style lang="scss" scoped>
@import "./scss/_variables.scss";


.Carton-card{
    margin: 5px;
    background-color: white;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    display: flex;
    flex-direction: column;
    padding: 10px;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    max-width: 150px;
    transition: all 1s ease;
    &:hover{
        transition: all .5s ease;
        background-color: $fancyOpacity;
    }
    .name{
        font-weight: bold;
        font-size: $fontsize;
    }
    .imgsection {
        font-size: 60px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        img{
            width: 100%;
            height: 100px;
        }
        margin: 15px 0;
    }
    .prix{
        font-size: $fontsize;
    }
}
input:checked + .Carton-card{
    background-color: $fancy;
}
</style>