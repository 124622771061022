<template>
  <div class="background-blue">
    <div class="Passwd">
      <img id="logo" alt="aventbirthday logo" src="../assets/logo.png" />
      <h2>Réservé aux professionnels</h2>
      <span>Mot de passe</span>
      <span :class="[{ 'good': checkOk }, 'verif-passwd']">
        <input
          @keyup="checkPasswd"
          v-model="passwd"
          placeholder="mot de passe"
          type="password"
          autofocus
        />
      </span>
      <button :class="{ 'animation-valide': checkOk }" :disabled="!checkOk" @click="changePage">Entrer</button>
    </div>
    <div class="slide-contact">
      <input type="checkbox" style="display: none;" id="contact-row" />
      <div class="contact-info">
        <div>Mot de passe oublié ?</div>
        <br />
        <div>
          <ion-icon name="call"></ion-icon>Tel : 07 71 70 79 22
        </div>
        <br />
        <div>
          <ion-icon name="mail"></ion-icon>contact@aventbirthday-pro.fr
        </div>
      </div>
      <label for="contact-row">
        <ion-icon name="call"></ion-icon>
        <span>Contact</span>
      </label>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

// import { login } from "../../api";

//import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Passwd',
  data() {
    return {
      goodPass: false,
      passwd: '',
      token: ''
    }
  },
  computed: {
    checkOk() {
      return this.goodPass
    }
  },
  methods: {
    checkPasswd() {
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      let urlencoded = new URLSearchParams();
      urlencoded.append("password", this.passwd);

      let requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
      };
      fetch("https://www.aventbirthday-pro.fr/api/users/login/", requestOptions)
        .then(response => response.text())
        .then(result => {
          let data = JSON.parse(result)
          if (data.error) {

            this.goodPass = false
            this.token = ''
          }
          else {
            this.token = data.token
            this.goodPass = true
          }
        })
      //.catch(error => console.log('error', error));
    },
    changePage() {
      let date = new Date(Date.now() + 3600000); // 1h
      date = date.toUTCString();

      document.cookie = 'authentification=' + this.token + ';path=/;domain=www.aventbirthday-pro.fr;expires=' + date + ';samesite="strict";secure';
      // document.cookie = 'authentification=' + this.token + ';path=/;domain=localhost;expires=' + date + ';samesite="strict";secure'; //TODO: changer le www.aventbirthday-pro.fr par le nom de domaine
      window.location.replace("/tarif");
    }
  },
  // components: {
  //   HelloWorld
  // }
}
</script>

<style lang="scss" scoped>
@import "./scss/_variables.scss";

.background-blue {
  background-color: $fancy;
  min-height: 100vh;
  .Passwd {
    position: relative;
    z-index: 3;
    padding: 100px 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    #logo {
      width: 60%;
      transition: all 1s ease;
    }
    & h2 {
      color: $blanchatre;
      margin: 40px 0 10px 0;
    }
    .verif-passwd {
      border-radius: 20px;
    }
    & input {
      outline: none;
      padding: 20px 30px;
      border-radius: 20px;
      border: solid $grey 1px;
      font-size: $fontsize;
      color: $fontcolor;
      &::placeholder {
        color: $grey;
        font-weight: bold;
      }
      &:focus-visible {
        border: solid 2px $focuscolor;
      }
    }
    & button {
      margin: 40px auto;
      color: $fancy;
      background-color: $blanchatre;
      transition: all 0.5s ease;
    }
    & h2 + span {
      color: $blanchatre;
      font-size: $fontsize;
      margin-bottom: 30px;
    }
  }
  .good {
    &::after {
      position: relative;
      z-index: 9;
      top: 5%;
      left: 20px;
      content: "";
      font-size: $fontsize;
      color: $blanchatre;
      font-weight: bolder;
    }
  }
  .animation-valide {
    animation: Validate 1s infinite;
    @keyframes Validate {
      0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
      }

      70% {
        transform: scale(1.2);
        box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
      }

      100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
      }
    }
  }

  .slide-contact {
    width: 100%;
    height: 20vh;
    position: fixed;
    z-index: 3;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    label {
      margin-right: 10px;
      position: absolute;
      top: 70vh;
      right: 0;
      z-index: 3;
      display: flex;
      background-color: $blanchatre;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      height: 60px;
      width: 70px;
      padding: 10px;
      border-radius: 100%;
      transition: all 0.5s ease;
      font-weight: bolder;
      & ion-icon {
        font-size: 20px;
      }

      &:hover {
        transition: all 0.5s ease;
        opacity: 0.8;
      }
    }

    .contact-info {
      transition: all 1s ease;
      position: absolute;
      top: 70vh;
      left: -1000px;
      z-index: 2;
      margin: 3px;
      height: 15vh;
      background-color: $blanchatre;
      padding: 10px 20px;
      border-radius: 15px;
      font-size: $fontsize;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      & div:first-child {
        font-size: 22px;
      }
    }
  }
  #contact-row:checked ~ .contact-info {
    transform: translateX(1000px);
    transition: all 1s ease;
  }
}
@media (max-width: 600px) {
  .background-blue .Passwd {
    #logo {
      width: 90%;
      transition: all 1s ease;
    }
  }
}
</style>