<template>
    <div id="gestion-order" class="gestion-order" :class="{'disable-scorll':true}">
        <DetailOrder v-if="detailInfo_.enseigne" :order="detailInfo_" @close="detailInfo={}" />
        <h2>Gestion des orders</h2>
        <table>
            <thead>
                <th>Status</th>
                <th>Paiment</th>
                <th>Enseigne</th>
                <th>Tel</th>
                <th>Date</th>
                <th>TTC</th>
                <th>Quantite</th>
                <th>Adresse</th>
                <th>ville</th>
                <th>Code Postal</th>
                <th>Id</th>
                <th></th>
            </thead>
            <tbody>
                <tr v-for="order in orders" :key="order">
                    <td data-label="Status" 

                        v-if="rowToEdit.row != order.id"
                    >{{ (order.detail.status == null || order.detail.status == '') ? ' - ' : order.detail.status }}</td>
                    <td data-label="Status"  v-if="rowToEdit.row == order.id">
                        <select v-model="rowToEdit.editStatus">
                            <option
                                v-for="statut in statusList"
                                :key="statut"
                                :value="statut"
                                :selected="selected(statut ,order.detail.status)"
                            >{{ statut }}</option>
                        </select>
                    </td>
                    <td data-label="Paiment" >{{ (order.detail.payment == null || order.detail.payment == '') ? ' - ' : order.detail.payment }}</td>
                    <td data-label="Enseigne" >{{ (order.enseigne == null || order.enseigne == '') ? ' - ' : order.enseigne }}</td>
                    <td data-label="Tel" >{{ (order.livraison.tel == null || order.livraison.tel == '') ? ' - ' : order.livraison.tel }}</td>
                    <td data-label="Date" >{{ (order.createdAt == null || order.createdAt == '') ? ' - ' : dateFormat(order.createdAt) }}</td>
                    <td data-label="TTC" >{{ (order.detail.TTC == null || order.detail.TTC == '') ? ' - ' : order.detail.TTC + ' €' }}</td>
                    <td data-label="Quantite" >{{ (order.detail.quantite == null || order.detail.quantite == '') ? ' - ' : order.detail.quantite }}</td>
                    <td data-label="Adresse" >{{ (order.livraison.addresse1 == null || order.livraison.addresse1 == '') ? ' - ' : order.livraison.addresse1 + ' ' + order.livraison.addresse2 }}</td>
                    <td data-label="ville" >{{ (order.livraison.ville == null || order.livraison.ville == '') ? ' - ' : order.livraison.ville }}</td>
                    <td data-label="Code Postal" >{{ (order.livraison.CP == null || order.livraison.CP == '') ? ' - ' : order.livraison.CP }}</td>
                    <td data-label="Id" >{{ (order.id == null || order.id == '') ? ' - ' : order.id }}</td>
                    <td v-if="rowToEdit.row != order.id">
                        <button @click="Edith(order)">
                            <ion-icon name="create"></ion-icon>
                        </button>
                        <button @click="openDetail(order)">
                            <ion-icon name="eye-sharp"></ion-icon>
                        </button>
                    </td>
                    <td v-if="rowToEdit.row == order.id">
                        <button @click="Update()">Ok</button>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="details"></div>
    </div>
</template>

<script>
import DetailOrder from "./Detail-order.vue";
export default {
    name: "Admin",
    data() {
        return {
            orders: [],
            rowToEdit: {
                row: 0,
                status: ""
            },
            statusList: [
                "Mail-Payment accepté",
                "Expedié",
                "à traiter",
                "Mail-En attente de Payment",
                "Commande annulée",
            ],
            detailInfo: {}
        };
    },
    methods: {
        selected(s1, s2) {
            return s1 == s2;
        },
        dateFormat(dateParam) {
            let test = new Date(dateParam);
            let day = test.getDate();
            let month = test.getMonth() + 1;
            let year = test.getFullYear();
            return day + "/" + month + "/" + year;
        },
        Edith(order) {
            this.rowToEdit = {
                row: order.id,
                editStatus: ""
            };
        },
        Update() {
            const api = require("../../api");
            let auth = api.getCookie("adminAuth");
            var myHeaders = new Headers();
            myHeaders.append("Authorization", "Bearer " + auth);
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
            var urlencoded = new URLSearchParams();
            urlencoded.append("newStatus", this.rowToEdit.editStatus);
            var requestOptions = {
                method: "PUT",
                headers: myHeaders,
                body: urlencoded,
                redirect: "follow"
            };
            fetch("https://www.aventbirthday-pro.fr/api/order/" + this.rowToEdit.row, requestOptions)
                .then(response => response.json())
                .then(result => {
                if (!result.error) {
                    this.rowToEdit = {
                        row: 0,
                        status: ""
                    };
                    window.location.replace("/admin");
                }
            })
                .catch(error => console.log("error", error));
        },
        openDetail(order) {
            this.detailInfo = order
        }
    },
    created() {
        const api = require("../../api");
        let auth = api.getCookie("adminAuth");
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + auth);
        var requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow"
        };
        fetch("https://www.aventbirthday-pro.fr/api/order", requestOptions)
            .then(response => response.json())
            .then(result => {
            for (let i = 0; i < result.length; i++) {
                const element = result[i];
                let data = {
                    id: element.id,
                    createdAt: element.createdAt,
                    detail: JSON.parse(element.detail),
                    facturation: JSON.parse(element.info_Facturation),
                    livraison: JSON.parse(element.info_Livraison),
                    enseigne: element.enseigne,
                };
                this.orders.push(data);
            }
        })
            .catch(error => console.log("error", error));
    },
    computed:{
        detailInfo_(){
            return this.detailInfo
        }
    },
    components: { DetailOrder }
}
</script>


<style lang="scss" scoped>
@import "./scss/_variables.scss";
.gestion-order {
    margin: 20px auto;
    background-color: $blanchatre;
    padding: 20px;
    width: 90%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    h2 {
        margin: 20px 0 50px 0;
    }
}
table {
    background-color: $fancy;
    border-radius: 5px;
    thead th {
        padding: 5px 8px;
    }
    tbody tr {
        text-align: center;
        td {
            margin: 2px 3px;
            padding: 0 5px;
            border-radius: 2px;
            background: white;
        }
    }
}
button{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px 5px;
    margin: 3px;
    text-align: center;
    font-size: $fontsize;
        ion-icon {
        color: white;
        font-size: $fontsize;
    }
}
@media screen and (max-width: 1250px) {
  .gestion-order{
    width: 90%;
    padding: 15px;
  }
  table {
    border: 0;
  }
  
  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  
  table tr {
    border-bottom: 3px solid #ddd;
    margin-bottom: .625em;
    overflow: auto;
  }
  
  table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: $fontsize;
    text-align: right;
  }
  
  table td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }
  
  table td:last-child {
    border-bottom: 0;
  }
}
.disable-scorll{
    overflow: hidden;
}
</style>