<template>
    <div class="product">
        <div class="info">
            <img :src="img" :alt="img" />
            <span>{{name}}</span>
        </div>
        <div class="changeArea">
            <button @click="remove" :disabled="disablemin">
                <ion-icon name="remove-circle-outline"></ion-icon>
            </button>
            <input @change="checkval" type="number" :max="max" v-model="locaCount" id="valeur" class="count" required>
            <button @click="add" :disabled="disablemax">
                <ion-icon name="add-circle-outline"></ion-icon>
            </button>
        </div>
    </div>
</template>

<script>
export default {
    props: ['image','name','count', 'rest'],
    data() {
        return {
            locaCount:this.count,
        }
    },
    methods: {
        add() {
            if (!this.disablemax || this.finished) {
                this.locaCount++
                this.$emit('add')
            }
        },
        remove() {
            if (!this.disablemin) {
                this.locaCount--
                this.$emit('remove')
            }
        },
        checkval(){
            if(this.checkinf)
            {
                document.getElementById('valeur').value=0
                this.locaCount=0
                this.$emit('setVal',0)
            }
            else if (this.rest==0) {
                this.locaCount=this.max
                this.$emit('setVal',this.max)
            }
            else if(this.checksup)
            {
                document.getElementById('valeur').value=this.max
                this.locaCount=this.max
                this.$emit('setVal',this.max)
            } else{
                // let newVal = Number(document.getElementById('valeur').value);
                    this.$emit('setVal',this.locaCount)                
            }
           
                
        }
    },
    computed: {
        img() {
            return 'gamme/' + this.image
        },
        disablemin() {
            return this.locaCount <= 0
        },
        disablemax() {
            return this.locaCount >= this.max || this.finished
        },
        max(){
            return this.count+this.rest
        },
        checkinf(){
            return this.locaCount<0
        },
        checksup(){
            return this.locaCount>this.max
        },
        checkInterval(){
            return this.checkinf || this.checksup
        }

    }
}
</script>

<style lang="scss" scoped>
@import "./scss/_variables.scss";

$sizeChangeArea: clamp(35px, 1vw, 55px);

button {
    padding: 0;
    margin: 0;
    background-color: $none;
    ion-icon {
        color: $fancy;
        font-size: $sizeChangeArea;
        cursor: pointer;
    }
    &:disabled{
        ion-icon{
            color: $grey;
        }
    }
}
.product {
    background-color: white;
    display: flex;
    flex-direction: row;
    border-radius: 20px;
    width: 90%;
    margin: 5px 0;
    .info {
        flex: 2;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 5px;
        img {
            width: 50%;
            min-width: 80px;
        }
        span {
            font-size: $fontsize;
            font-weight: bolder;
        }
    }
    .changeArea {
        flex: 6;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-direction: row;
        .count{
            font-size: $sizeChangeArea;
            width: 30%;
            text-align: center;
            border: none;
        }
    }
}

@media (max-width:600px) {
        .product .changeArea {
        // flex-direction: column;
        width: 100%;
        .count{
            font-size: $sizeChangeArea;
            width: 100px;
            text-align: center;
            border: none;
        }
    }
}
@media (max-width:380px) {
        .product .changeArea {
        // flex-direction: column;
        width: 100%;
        .count{
            font-size: $sizeChangeArea;
            width: 70px;
            text-align: center;
            border: none;
        }
    }
}
</style>