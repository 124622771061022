<template>
  <div class="PaymentValide">
    <h1>Votre commande a été enregistrée avec Succès <ion-icon name="checkmark-circle-outline"></ion-icon></h1>

    <button @click="redirectPage('tarif')">Retour au site</button>
    
    <div class="infoBanque">
            <h2>Informations Bancaires</h2>
            <i>(Uniquement pour les payment par virement)</i>
            <br><br>
            <h2>Banque : </h2>
            <p>CIC ST GELY DU FESC</p>

            <h2>NUMÉRO DU COMPTE :</h2>
            <p>00020059701</p>

            <h2>CODE GUICHET :</h2>
            <p>19253</p>

            <h2>IBAN :</h2>
            <p>FR7610057192530002005970105</p>

            <h2>BIC :</h2>
            <p>CMCIFRPP</p>
        </div>
  </div>
</template>

<script>
export default {
  name: 'PaymentValide',  
  data() {
      return {

      }
  },
  components:{
  },
  methods:{
    redirectPage(page){
      window.location.replace("/"+page);
    }
  },
  created() {
    const api = require('../../api')
    api.checkCookie()
  },
}
</script>

<style lang="scss" scoped>
@import "./scss/_variables.scss";

h1{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-flow: row wrap;
    ion-icon{
        margin-left: 20px;
        font-size: 40px;
    }
}
.PaymentValide{
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  min-height: 120vh;
  h1{
    margin: 50px auto;
    text-align: center;
  }
}
.infoBanque{
    text-align: center;
    margin: 50px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: $blanchatre;
    border-radius: 20px;
    padding: 20px;
    width: 50%;
    min-width: 300px;
}
  button{
      background-color: $fancy;
      color: $blanchatre;
    }

</style>