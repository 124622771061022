<template>
  <div class="admin">
    <h1>This is an admin page</h1>
    <GestionOrders />
    <main>  
      <GestionCarton @error-state="Errors" />
      <gestionCodePromo />
      <AddUser @error-state="errorState" /> 
    </main>
    <GestionUser @error-state="Errors" />
    <div class="login" v-if="!checkLogin">
      <h1>Connection espace Administrateur</h1>
      <div class="group">
        <div class="col">
          <label for>Identifiant</label>
          <input type="text" v-model="login"/>
        </div>
        <div class="col">
          <label for>Mot de passe</label>
          <input type="password" v-model="password" />
        </div>
        <button  @click="checkInfo()">Entrer</button>
      </div>
    </div>
  </div>
</template>

<script>
import GestionUser from '../components/GestionUser.vue'
import GestionCarton from '../components/GestionCarton.vue'
import GestionOrders from '../components/GestionOrders'
import AddUser from '../components/addUser'
import gestionCodePromo from '../components/GestionCodePromo'
export default {
  name: 'Admin',
  data() {
    return {
      isLogin: false,
      login:'',
      password:'',
      auth:null,
      errorsList:[]
    }
  },
  methods: {
    Errors(e){
      this.errorsList =e
      setTimeout(()=>this.errorsList =[],3000)
    },
    checkInfo() {

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      var urlencoded = new URLSearchParams();
      urlencoded.append("login", this.login);
      urlencoded.append("passWord", this.password);

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
      };

      fetch("https://www.aventbirthday-pro.fr/api/admin", requestOptions)
        .then(response => response.json())
        .then(result =>{
          if (!result.error) {
            let date = new Date(Date.now() + 3600000); // 1h
      date = date.toUTCString();
          document.cookie = 'adminAuth=' + result.token + ';path=/;domain=www.aventbirthday-pro.fr;expires=' + date + ';samesite="strict";secure';
          // document.cookie = 'adminAuth=' + result.token + ';path=/;domain=localhost;expires=' + date + ';samesite="strict";secure';
            this.isLogin= true
            window.location.replace("/admin");
          }
          else{
            this.isLogin= false
          }
        })
        .catch(error => console.log('error', error));


      // fin fonction 
    }
  },
  computed: {
    checkLogin() {
      return this.isLogin
    }
  },
  components: {
    GestionUser,
    AddUser,
    GestionCarton,
    gestionCodePromo,
    GestionOrders
  },
  created() {
    const api = require('../../api')

    let auth = api.getCookie('adminAuth')

    if (auth != null) {
      this.auth == auth
      this.isLogin= true
    }
    else
      this.isLogin= false
  }
}
</script>


<style lang="scss" scoped>
@import "./scss/_variables.scss";

.admin{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h1{
    margin: 50px auto;
  }
}
button {
      margin: 40px auto;
      color: $fancy;
      background-color: $blanchatre;
      transition: all 0.5s ease;
    }
.login {
  position: fixed;
  top: 0;
  height: 100vh;
  width: 100%;
  min-width: 300px;
  background: $fancy;
  margin: auto;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  h1 {
    color: $blanchatre;
  }
  .group {
    width: 40%;
    min-width: 300px;
    color: red;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 5px 10px;
  label {
    margin: 5px 0;
    font-weight: bolder;
  }
  input {
    padding: 7px;
    border-radius: 20px;
    border: solid $grey 1px;
    font-size: $fontsize;
    color: $fontcolor;
    outline: none;
    &::placeholder {
      color: $grey;
      font-weight: bold;
    }
    &:focus-visible {
      border: solid 2px $focuscolor;
    }
  }
}
.col .isCorrect {
  border: solid $fancy 2px;
}


</style>