<template>
    <div class="gestion-codePromo">
        <h2>Gestion des codePromos</h2>
        <table>
            <thead>
                <th>code</th>
                <th>active</th>
                <th>min</th>
                <th>montant</th>
                <th>pourcent</th>
                <th>pannierReduct</th>
                <th>Id</th>
                <th></th>
                <th></th>
            </thead>
            <tbody>
                <tr v-for="codePromo in codePromos" :key="codePromo">
                    <td data-label="code"
                        v-if="codePromo.id != rowToEdit.row"
                    >{{ (codePromo.code == null || codePromo.code == '') ? ' - ' : codePromo.code }}</td>
                    <td data-label="active" v-if="codePromo.id != rowToEdit.row" class="multiusage">
                        <ion-icon v-if="codePromo.active" class="green" name="checkmark-circle"></ion-icon>
                        <ion-icon v-if="!codePromo.active" class="red" name="close-circle"></ion-icon>
                    </td>
                    <td data-label="min"
                        v-if="codePromo.id != rowToEdit.row"
                    >{{ (codePromo.min == null || codePromo.min == '') ? ' - ' : codePromo.min }}</td>
                    <td data-label="montant"
                        v-if="codePromo.id != rowToEdit.row"
                    >{{ (codePromo.montant == null || codePromo.montant == '') ? ' - ' : codePromo.montant + ' €' }}</td>
                    <td data-label="pourcent"
                        v-if="codePromo.id != rowToEdit.row"
                    >{{ (codePromo.pourcent == null || codePromo.pourcent == '') ? ' - ' : codePromo.pourcent + ' %' }}</td>
                    <td data-label="pannierReduct" v-if="codePromo.id != rowToEdit.row" class="multiusage">
                        <ion-icon
                            v-if="codePromo.pannierReduct"
                            class="green"
                            name="checkmark-circle"
                        ></ion-icon>
                        <ion-icon v-if="!codePromo.pannierReduct" class="red" name="close-circle"></ion-icon>
                    </td>
                    <td data-label="Id" v-if="codePromo.id != rowToEdit.row">{{ codePromo.id }}</td>
                    <td data-label="Modiffier" v-if="codePromo.id != rowToEdit.row">
                        <button @click="Edith(codePromo)">
                            <ion-icon name="create"></ion-icon>
                        </button>
                    </td>
                    <td data-label="Supprimer" v-if="codePromo.id != rowToEdit.row">
                        <button @click="supprimer(codePromo)">
                            <ion-icon name="trash"></ion-icon>
                        </button>
                    </td>
                    <!-- Edtith zonne -->
                    <td data-label="code" v-if="codePromo.id == rowToEdit.row"
                    >{{ (codePromo.code == null || codePromo.code == '') ? ' - ' : codePromo.code }}</td>
                    <td data-label="active" v-if="codePromo.id == rowToEdit.row">
                        <input type="checkbox" v-model="rowToEdit.active" />
                    </td>
                    <td data-label="min" v-if="codePromo.id == rowToEdit.row">
                        <input type="number" v-model="rowToEdit.min" />
                    </td>
                    <td data-label="montant" v-if="codePromo.id == rowToEdit.row"
                    >{{ (codePromo.montant == null || codePromo.montant == '') ? ' - ' : codePromo.montant }}</td>
                    <td data-label="pourcent" v-if="codePromo.id == rowToEdit.row"
                    >{{ (codePromo.pourcent == null || codePromo.pourcent == '') ? ' - ' : codePromo.pourcent }}</td>
                    <td data-label="pannierReduct" v-if="codePromo.id == rowToEdit.row" class="multiusage">
                        <ion-icon
                            v-if="codePromo.pannierReduct"
                            class="green"
                            name="checkmark-circle"
                        ></ion-icon>
                        <ion-icon v-if="!codePromo.pannierReduct" class="red" name="close-circle"></ion-icon>
                    </td>
                    <td data-label="Id" v-if="codePromo.id == rowToEdit.row">{{ codePromo.id }}</td>
                    <td data-label="Valider" v-if="codePromo.id == rowToEdit.row">
                        <button @click="update()">Ok</button>
                    </td>
                    <td data-label="" v-if="codePromo.id == rowToEdit.row"></td>
                </tr>
            </tbody>
        </table>
        <div class="form">
            <div class="same-row">
                <div class="col">
                    <label for>code</label>
                    <input required type="text" v-model="addCodePromo.code" />
                </div>
                <div class="col">
                    <label for>min</label>
                    <input type="number" v-model="addCodePromo.min" />
                </div>
            </div>
            <div class="same-row">
                <div class="col">
                    <label for>montant</label>
                    <input @change="checkValue()" type="number" v-model="addCodePromo.montant" />
                </div>
                <div class="col">
                    <label for>pourcent</label>
                    <input @change="checkValue()" type="number" v-model="addCodePromo.pourcent" />
                </div>
            </div>
            <div class="checkbox">
                <label for="activated">active</label>
                <input type="checkbox" id="activated" v-model="addCodePromo.active" />
            </div>
            <div class="checkbox">
                <label for="reducPannier">pannierReduct</label>
                <input type="checkbox" id="reducPannier" v-model="addCodePromo.pannierReduct" />
            </div>

            <button @click="ajout()">Ajouter</button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Admin',
    data() {
        return {
            codePromos: [],
            rowToEdit: {
                row: 0,
                active: true
            },
            addCodePromo: {
                code: '',
                active: true,
                min: 3,
                montant: 0,
                pourcent: 0,
                pannierReduct: false
            }
        }
    },
    methods: {
        Edith(codePromo) {
            let rowToEdit = {
                row: codePromo.id,
                active: codePromo.active,
                min: codePromo.min,
            }
            this.rowToEdit = rowToEdit
        },
        update() {

            const api = require('../../api')
            let auth = api.getCookie('adminAuth')

            var myHeaders = new Headers();
            myHeaders.append("Authorization", "Bearer " + auth);
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

            var urlencoded = new URLSearchParams();
            urlencoded.append("active", this.rowToEdit.active);
            urlencoded.append("min", this.rowToEdit.min);

            var requestOptions = {
                method: 'PUT',
                headers: myHeaders,
                body: urlencoded,
                redirect: 'follow'
            };

            fetch("https://www.aventbirthday-pro.fr/api/codePromo/" + this.rowToEdit.row, requestOptions)
                .then(response => response.text())
                .then(result => {
                    console.log(result);
                    if (!result.error) {
                        this.rowToEdit = {
                            row: 0,
                            name: '',
                            unit: 0,
                            prixUnit: 5,
                            quantiteMax: 1,
                            port: 0
                        }
                        window.location.replace("/admin");
                    }
                })
                .catch(error => console.log('error', error));
        },
        supprimer(codePromo) {
            console.log(codePromo);
            const api = require('../../api')
            let auth = api.getCookie('adminAuth')

            var myHeaders = new Headers();
            myHeaders.append("Authorization", "Bearer " + auth);

            var urlencoded = new URLSearchParams();

            var requestOptions = {
                method: 'DELETE',
                headers: myHeaders,
                body: urlencoded,
                redirect: 'follow'
            };

            fetch("https://www.aventbirthday-pro.fr/api/codePromo/" + codePromo.id, requestOptions)
                .then(response => response.json())
                .then(() => {
                    window.location.replace("/admin")
                })
                .catch(error => console.log('error', error));
        },
        ajout() {
            const api = require('../../api')
            let auth = api.getCookie('adminAuth')

            var myHeaders = new Headers();
            myHeaders.append("Authorization", "Bearer " + auth);
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

            var urlencoded = new URLSearchParams();
            urlencoded.append("code", this.addCodePromo.code);
            urlencoded.append("active", this.addCodePromo.active);
            urlencoded.append("min", this.addCodePromo.min);
            urlencoded.append("montant", this.addCodePromo.montant);
            urlencoded.append("pourcent", this.addCodePromo.pourcent);
            urlencoded.append("pannierReduct", this.addCodePromo.pannierReduct);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: urlencoded,
                redirect: 'follow'
            };

            fetch("https://www.aventbirthday-pro.fr/api/codePromo", requestOptions)
                .then(response => response.json())
                .then(() => {
                    window.location.replace("/admin")
                })
                .catch(error => console.log('error', error));
        },
        errorState(e) {
            this.$emit('error-state', e)
        },
        checkValue(){
            if (this.checkMontant) {
                this.addCodePromo.pourcent=0
            }else if (this.checkPourcent) {
                this.addCodePromo.montant=0
            }
            else
            {
                this.addCodePromo.montant=0
                this.addCodePromo.pourcent=0
            }
        }
    },
    computed: {
        checkMontant(){
            return this.addCodePromo.montant > 0
        },
        checkPourcent(){
            return this.addCodePromo.pourcent > 0
        }
    },
    components: {
    },
    created() {
        const api = require('../../api')
        let auth = api.getCookie('adminAuth')
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + auth);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch("https://www.aventbirthday-pro.fr/api/codepromo", requestOptions)
            .then(response => response.json())
            .then(result => {
                this.codePromos = result
            })
            .catch(error => console.log('error', error));
    }
}
</script>


<style lang="scss" scoped>
@import "./scss/_variables.scss";
.form {
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    margin: 20px auto;
    button {
        margin: 30px 0;
    }
}
.gestion-codePromo {
    margin: 20px 0;
    background-color: $blanchatre;
    padding: 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    h2 {
        margin: 20px 0 50px 0;
    }
}
table {
    background-color: $fancy;
    border-radius: 5px;
    thead th {
        padding: 5px 8px;
    }
    tbody tr {
        text-align: center;
        td {
            margin: 2px 3px;
            padding: 0 5px;
            border-radius: 2px;
            background: white;
        }
    }
}
.multiusage {
    ion-icon {
        font-size: 30px;
    }
    .green {
        color: green;
    }
    .red {
        color: red;
    }
}
.checkbox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 50%;
    min-width: 200px;
    margin: 20px 0;
}
.same-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 10px;
    justify-content: center;
    align-items: center;
}
.col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 5px 10px;
    label {
        margin: 5px 0;
        font-weight: bolder;
    }
    input {
        padding: 7px;
        border-radius: 20px;
        border: solid $grey 1px;
        font-size: $fontsize;
        color: $fontcolor;
        outline: none;
        &::placeholder {
            color: $grey;
            font-weight: bold;
        }
        &:focus-visible {
            border: solid 2px $focuscolor;
        }
    }
}
.col .isBad {
    border: solid $bad 2px;
}
.col .isCorrect {
    border: solid $fancy 2px;
}

button {
    padding: 5px 10px;
    font-size: $fontsize;
    ion-icon {
        color: white;
        font-size: $fontsize;
    }
}

@media screen and (max-width: 650px) {
  .gestion-user{
    width: 90%;
    padding: 5px;
  }
  table {
    border: 0;
    width: 95%;
  }
  
  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  
  table tr {
    border-bottom: 3px solid #ddd;
    margin-bottom: .625em;
    overflow: auto;
  }
  
  table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: $fontsize;
    text-align: right;
  }
  
  table td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }
  
  table td:last-child {
    border-bottom: 0;
  }
}
</style>